.mantine-Card-section > .mantine-Table-table thead tr th:first-child {
  padding-left: var(--card-padding);
}

.mantine-Card-section > .mantine-Table-table tbody tr td:first-child {
  padding-left: var(--card-padding);
}

.mantine-Card-section > .mantine-Table-table thead tr th:last-child {
  padding-right: var(--card-padding);
}

.mantine-Card-section > .mantine-Table-table tbody tr td:last-child {
  padding-right: var(--card-padding);
}

.ag-cell .mantine-Text-root {
  font-size: 12px;
}
